
import { mostrarSoloDosDecimales } from '@/funciones/funciones';

/**
 * Calcula el consto del paquete
 * @param {*} paquete 
 */
function paquteCalcularCosto(paquete) {
  let costoTotal = NaN;
  const tiposEnvio = [ 'Aereo', 'Maritimo'];
  // Requisitos para calcular monto
  if (
    !paquete.direccionDestino ||
    !tiposEnvio.includes(paquete.envio) ||
    !paquete.ancho ||
    !paquete.alto ||
    !paquete.largo ||
    !paquete.peso
  ) {
    return costoTotal;
  }
  if (paquete.envio === 'Aereo') {
    if (
      !paquete.direccionDestino.tasaAerea ||
      !paquete.direccionDestino.minAerea
    ) {
      return costoTotal;
    }
  }
  if (paquete.envio === 'Maritimo') {
    if (
      !paquete.direccionDestino.tasaMaritima ||
      !paquete.direccionDestino.minMaritima
    ) {
      return costoTotal;
    }
  }

  const direccion = paquete.direccionDestino
  const costo = Number(paquete.costo)
  const valor = Number(paquete.valorDeclarado)
  let seguro = 0;

  if(valor){
    seguro = valor * 0.05
    costoTotal = costo + seguro
  } else {
    // Si se establece manualmente en "0" el valor, se reinician los valores
    seguro = 0
    costoTotal = costo
  }
  // Calculo de pesos
  const pesoVolumetrico = (paquete.ancho * paquete.alto * paquete.largo) / 166
  const peso = paquete.peso > pesoVolumetrico
    ? paquete.peso
    : pesoVolumetrico
  const piesCubicos = (paquete.ancho * paquete.alto * paquete.largo) / 1728
  // Calcular según tipo de envío
  switch (paquete.envio) {
    case "Aereo":
      if(peso < direccion.minAerea){
        // Si el peso es menor que la tasa minima aerea se usa la tasa minima aerea
        costoTotal += (direccion.tasaAerea * direccion.minAerea)
      } else {
        // En caso contrario se usa el peso * tasa aerea
        costoTotal += (direccion.tasaAerea * peso)
      }
      break;
    case "Maritimo":
      if(piesCubicos < direccion.minMaritima){
        // Si los piesCubicos es menor que la tasa minima Maritima se usa la tasa minima Maritima
        costoTotal += (direccion.tasaMaritima * direccion.minMaritima)
      } else {
        // En caso contrario se usan los piesCubicos * tasa Maritima
        costoTotal += (direccion.tasaMaritima * piesCubicos)
      }
      break;
  }
  return mostrarSoloDosDecimales(costoTotal);
}

export {paquteCalcularCosto};
