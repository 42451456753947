// Exporta el PaqueteEstatus definido en data
const PaqueteEstatus = {
  // Estatus disponibles de los paquetes
  EsperaMiami: "En almacén de Miami",
  Devuelto: "Devuelto",
  Reempacado: "Re-empacado para despacho",
  Consolidado: "Consolidado para salida",
  NavieraAerolinea: "Con Naviera / Aerolínea",
  TransitoInternacional: "Tránsito internacional",
  EnPuertoAeropuerto: "En puerto/aeropuerto",
  ProcesoAduana: "En proceso de aduana",
  EsperaCaracas: "Recibido en almacén de Caracas",
  ListoDespacho: "Listo para despacho de entrega",
  TransitoNacional: "Ruta para la entrega",
  Entregado: "Entregado",
};

// Exporta el PaqueteTipoEmpaque definido en data
const PaqueteTipoEmpaque = ["Caja", "Sobre"];

// Exporta el PaqueteTipoEmpaqueIconos definido en data
const PaqueteTipoEmpaqueIconos = {
  // Iconos de los empaques disponibles
  Caja: "box",
  Sobre: "envelope",
};

// Exporta el PaqueteTipos definido en data
const PaqueteTipos = [
  // Tipos de paquete disponibles
  "Individual",
  "Reempaque",
];
const regExpFoto = /\.(jpg|jpeg|png)$/i;
const endpointPublicBackend =
  process.env.VUE_APP_PUBLIC_ENDPOINT || "http://localhost:4001/";

export {
  PaqueteEstatus,
  PaqueteTipoEmpaque,
  PaqueteTipoEmpaqueIconos,
  PaqueteTipos,
  regExpFoto,
  endpointPublicBackend,
};
